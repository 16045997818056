import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Html le vénérable standard qui a donné naissance au web continue d'évoluer. Le bouquet de technologies regroupées sous le drapeau `}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Html5"
      }}>{`Html5`}</a>{`: html, css, javascript, svg, canvas, ... donne les pleins pouvoirs à la page web une fois chargée dans un navigateur moderne:`}</p>
    <ul>
      <li parentName="ul">{`récupérer les données à présenter (base de données, service web, tableur Excel, csv ou autre, ..)`}</li>
      <li parentName="ul">{`calculer les indicateurs et tracer les graphiques`}</li>
      <li parentName="ul">{`se mettre en page en fonction de la taille de l'écran, du type de l'appareil (PC, tablette, smartphone) et du pointage (souris ou doigt).`}</li>
      <li parentName="ul">{`et même fonctionner en l'absence de connexion internet si nécessaire en se synchronisant à la reconnexion. `}</li>
    </ul>
    <br />
    <p>{`Elle devient une vraie application: `}<a parentName="p" {...{
        "href": "https://perceptible.fr/spa"
      }}><strong parentName="a">{`la webapp`}</strong></a>{`. Plus besoin de serveurs et d'architecture matérielle complexe pour servir les requêtes, chacun apporte sa machine, sa puissance de calcul et sa bande passante.`}</p>
    <iframe style={{
      "border": "0px"
    }} src="/sandbox/bullet/k.html" scrolling="no" width="100%" height="200px">
    </iframe>
    <p>{`Les jauges ci dessus sont calculées et mises en page au chargement. Il ne s'agit pas d'images statiques mais de tracés dynamiques calculés en temps réel par le navigateur. Pour s'en convaincre: `}</p>
    <ul>
      <li parentName="ul">{`Zoomer la page (ctrl +) pour vérifier qu'il n'y a pas de pixelisation`}</li>
      <li parentName="ul">{`Cliquer sur les jauges pour lancer/arrêter une animation`}</li>
    </ul>
    <h2>{`A propos des jauges`}</h2>
    <p>{`Un commentaire sur les jauges. Commençons par expliquer les précédentes. `}</p>
    <ul>
      <li parentName="ul">{`une valeur représentée par la barre horizontale noire, `}</li>
      <li parentName="ul">{`trois zones (rouge, orange, vert) représentant l'interprétation de la valeur: `}{`[pas bien, Ok, super]`}{`,`}</li>
      <li parentName="ul">{`chaque jauge est compacte et renvoie un signal global de couleur (rouge, orange, vert) qui permet à l'oeil de consolider l'état global,`}</li>
    </ul>
    <br />
    <p>{`Essayons de comparer à d'autres jauges, plus volumineuses, plus anciennes:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "385px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.74233128834356%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsSAAALEgHS3X78AAABOUlEQVQY02P4jwz+/gWRP7/9P7vx3+rmP3d7/3/q/f/jMlDs37+//zEAA4L55w+IvHXkX63l/ySef9G83w/p/X/I8veOxJ9Xef//fgLr/odNM9jOH8dOf87x+p8t9D9V5F+m4o9jtv8eSf6/J/DuuvzSKxO+QUxH1/wPZN6/Hz9e6ug/Yub7XBL0v1Dqb7Lk58M2/x9y/7hjWH40U36t7Yrba0C2ILmfAe7g7xs3PWFgeMbM/kRQ6nOx//9cxb/nLH99MK08nK63wstis2vU/ozff36Dnf4PSfNvkNCH/MJHDAxPWFgeA43g5H7UWb5qToZ7rCZvrJLWFFODTQ5mm9xuvr2DbDnC2d/OnX+/cvWH9Rvfrdvwc+Pm47v3h0SlTJ4+88Dtg/ufHtn36PC+h4fff/8AVg61GQCYwEoj2GeoZwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "jauge",
          "title": "jauge",
          "src": "/static/5626f968cc88f853bb249c3301e84d98/409e6/jauge.png",
          "srcSet": ["/static/5626f968cc88f853bb249c3301e84d98/222b7/jauge.png 163w", "/static/5626f968cc88f853bb249c3301e84d98/ff46a/jauge.png 325w", "/static/5626f968cc88f853bb249c3301e84d98/409e6/jauge.png 385w"],
          "sizes": "(max-width: 385px) 100vw, 385px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`L'aiguille noire qui se déplace sur des secteurs colorés. Une conception très légitime pour un dispositif mécanique soumis à des contraintes: `}</p>
    <ul>
      <li parentName="ul">{`la rotation est plus facile à réaliser que la translation. `}</li>
      <li parentName="ul">{`Les couleurs et échelles sont fixes (incrustées dans le dispositif), c'est l'aiguille noire qui se déplace.`}</li>
    </ul>
    <p>{`Mais pourquoi donc reprendre ces limitations sur un écran ? Et même sur du papier ?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      